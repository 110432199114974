export const placeHeaders = [
  {
    order: 1,
    label: 'ID',
    value: 'id',
  },
  {
    order: 2,
    label: 'Название',
    value: 'name',
  },
  {
    order: 3,
    label: 'Тип площадки',
    value: 'place_type_ru',
  },
  {
    order: 4,
    label: 'Город',
    value: 'city',
  },
  {
    order: 5,
    label: 'Статус',
    value: 'status',
  },
  {
    order: 6,
    label: 'Описание',
    value: 'description_exists',
  },
  {
    order: 7,
    label: 'Изображение',
    value: 'picture_exists',
  },
  {
    order: 8,
    label: 'Владелец данных',
    value: 'source_owner',
  },
  {
    order: 9,
    label: 'Обогащение данных',
    value: 'data_enricher',
  },
  {
    order: 10,
    label: 'Изменено админом',
    value: 'changed_from_admin',
  },
];

export const placeFilters = {
  ordering: '',
  q: '',
  place_type: '',
  place_type_ru: '',
  city_id: [],
  status: '',
  description_exists: '',
  picture_exists: '',
  changed_from_admin: '',
  vk_id: '',
};

export const placeBlank = {
  id: '',
  slug: '',
  name: '',
  city: '',
  status: false,
  description: '',
  email: '',
  phone: [],
  work_time: '',
  info: '',
  address: '',
  undergrounds: [],
  geo_lat: 0,
  geo_lon: 0,
  image_urls: [],
  picture: '',
  picture_original: '',
  vk_url: '',
  vk_id: null,
  weight: 1,
  place_type_ru: '',
  source_owner: '',
  data_enricher: [],
  changed_from_admin: false,
};

export const placeTypeOptions = [
  { value: 'Концертный зал', label: 'Концертный зал' },
  { value: 'Музей', label: 'Музей' },
  { value: 'Театр', label: 'Театр' },
  { value: 'Кинотеатр', label: 'Кинотеатр' },
  { value: 'Клуб', label: 'Клуб' },
  { value: 'Стадион', label: 'Стадион' },
  { value: 'Другое', label: 'Другое' },
];
