import React, { FC, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Div,
  Group,
  Input,
  Placeholder,
  Select,
  Spinner,
  Text,
  Title,
} from '@vkontakte/vkui';
import {
  Icon24ListDeleteOutline,
  Icon16SearchOutline,
  Icon16PaymentCardOutline,
  Icon16CancelCircleOutline,
  Icon16RoubleArrowBackwardOutline,
  Icon16Replay,
} from '@vkontakte/icons';
import { ITableHeader } from 'lib/types';
import { ApiResponse } from 'lib/api/api';
import { parseFiltersUrl } from 'lib/utils/parseFunction';
import { handleKeyDown } from 'lib/utils/handleKeyDown';
import { IOrder } from './types.orders';
import {
  orderFilters,
  orderHeaders,
  orderStatusOptions,
} from './constants.orders';
import TableSetup from 'components/common/TableSetup';
import { formatPrice } from 'lib/utils/formatPrice';
import CustomSortIcon from 'components/common/CustomSortIcon';
import { parseDateFrom } from 'lib/utils/date.utils';
import { ADMIN_ORDERS_ROUTE, dataProviderOptions } from 'lib/constants';
import useFetch from 'lib/hooks/useFetch';
import useParams from 'lib/hooks/useParams';
import FiltersWrapper from 'components/common/FiltersWrapper';
import FiltersButton from 'components/common/FiltersButton';
import FormItemPanel from 'components/common/FormItemPanel';
import PaginationComponent from 'components/common/Pagination';
import CityChipsSelect from 'components/common/CityChipsSelect';
import DateRangePicker from 'components/common/DateRangePicker';

const Orders: FC = () => {
  const [activeHeaders, setActiveHeaders] = useState<ITableHeader[]>([]);
  const { data, loading, fetchData } = useFetch<ApiResponse<IOrder[]>>();
  const location = useLocation();
  const {
    filters,
    setFilters,
    addFiltersAndUpdateUrl,
    reset,
    handleChange,
    openFilters,
    handletoggleFilters,
    setSortingFunc,
    sorting,
    currentPage,
    setCurrentPage,
  } = useParams(orderFilters);
  const {
    number,
    client_order_id,
    email,
    phone,
    user_id,
    q,
    city_id,
    status,
    provider,
    fee,
  } = filters;
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [createdAtFrom, setCreatedAtFrom] = useState<Date | undefined>(
    undefined,
  );
  const [createdAtTo, setCreatedAtTo] = useState<Date | undefined>(undefined);
  const [seanceStartFrom, setSeanceStartFrom] = useState<Date | undefined>(
    undefined,
  );
  const [seanceStartTo, setSeanceStartTo] = useState<Date | undefined>(
    undefined,
  );

  const handleResetFilters = useCallback(() => {
    reset();
    setCreatedAtFrom(undefined);
    setCreatedAtTo(undefined);
    setSeanceStartFrom(undefined);
    setSeanceStartTo(undefined);
  }, [
    reset,
    setCreatedAtFrom,
    setCreatedAtTo,
    setSeanceStartFrom,
    setSeanceStartTo,
  ]);

  const fetchListFunction = () => {
    const queryParams = location.search ? parseFiltersUrl(location.search) : '';

    fetchData({
      path: `/order/orders/?page=${currentPage - 1}${queryParams && `&${queryParams}`}`,
      method: 'get',
    });
  };

  useEffect(() => {
    fetchListFunction();
  }, [location.search, currentPage]);

  useEffect(() => {
    if (!loading && data?.results) {
      setOrders(data.results);
    }
  }, [loading, location.search]);

  useEffect(() => {
    const storedHeaders = localStorage.getItem('columnOrder_orders');

    if (storedHeaders) {
      setActiveHeaders(JSON.parse(storedHeaders));
    } else {
      setActiveHeaders(orderHeaders);
    }
  }, []);

  return (
    <>
      {loading ? (
        <Div style={{ width: '100%', height: '400px' }}>
          <Spinner size="medium" className="spinner" />
        </Div>
      ) : (
        <Group>
          <Div className="flex items-center gap-2">
            <FiltersButton
              openFilters={openFilters}
              toggle={handletoggleFilters}
            />
            <TableSetup
              tableId="orders"
              headers={orderHeaders}
              onActiveHeadersChange={setActiveHeaders}
            />
          </Div>
          {openFilters && (
            <FiltersWrapper
              reset={handleResetFilters}
              addFiltersAndUpdateUrl={addFiltersAndUpdateUrl}
            >
              <FormItemPanel top="Номер заказа">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="number"
                  placeholder="Укажите номер заказа"
                  value={number as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="Клиентский номер заказа">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="client_order_id"
                  placeholder="Укажите номер заказа"
                  value={client_order_id as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="Название события">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="q"
                  placeholder="Укажите название события"
                  value={q as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="Номер телефона">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="phone"
                  placeholder="Укажите номер телефона"
                  value={phone as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="E-mail">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="email"
                  placeholder="Укажите e-mail"
                  value={email as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <FormItemPanel top="ID пользователя">
                <Input
                  onKeyDown={(event) => {
                    handleKeyDown(event, addFiltersAndUpdateUrl);
                  }}
                  className="vkui-input"
                  name="user_id"
                  placeholder="Укажите ID пользователя"
                  value={user_id as string}
                  onChange={handleChange}
                  before={<Icon16SearchOutline fill="#99A2AD" />}
                />
              </FormItemPanel>
              <CityChipsSelect
                city_id={city_id}
                filters={filters}
                setFilters={setFilters}
                reset={handleResetFilters}
                filterKey={'city_id'}
              />
              <FormItemPanel top="Поставщик" htmlFor="provider">
                <Select
                  className="vkui-select"
                  id="provider"
                  name="provider"
                  placeholder="Выберите поставщика"
                  value={provider as string}
                  onChange={handleChange}
                  allowClearButton
                  options={dataProviderOptions}
                />
              </FormItemPanel>
              <FormItemPanel top="Статус заказа" htmlFor="status">
                <Select
                  className="vkui-select"
                  id="status"
                  name="status"
                  placeholder="Выберите статус"
                  value={status as string}
                  onChange={handleChange}
                  allowClearButton
                  options={orderStatusOptions}
                />
              </FormItemPanel>
              <FormItemPanel top="Комиссия" htmlFor="fee">
                <Select
                  className="vkui-select"
                  id="fee"
                  name="fee"
                  placeholder="Наличие комиссии"
                  value={fee as string}
                  onChange={handleChange}
                  allowClearButton
                  options={[
                    { value: 'true', label: 'Есть' },
                    { value: 'false', label: 'Нет' },
                  ]}
                />
              </FormItemPanel>
              <div className="z-10">
                <DateRangePicker
                  title={'Дата и время заказа'}
                  withTime={true}
                  from={createdAtFrom}
                  setFrom={setCreatedAtFrom}
                  fromFilterKey={'created_at__from'}
                  to={createdAtTo}
                  setTo={setCreatedAtTo}
                  toFilterKey={'created_at__to'}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
              <div className="z-10">
                <DateRangePicker
                  title={'Дата мероприятия'}
                  withTime={false}
                  from={seanceStartFrom}
                  setFrom={setSeanceStartFrom}
                  fromFilterKey={'start_date__from'}
                  to={seanceStartTo}
                  setTo={setSeanceStartTo}
                  toFilterKey={'start_date__to'}
                  filters={filters}
                  setFilters={setFilters}
                />
              </div>
            </FiltersWrapper>
          )}
          {orders && orders.length ? (
            <>
              <div className="custom-scrollbar overflow-auto pb-2">
                <table className="w-full">
                  <thead>
                    <tr className="border-y-1 border-color-steel-gray-80">
                      {activeHeaders.map((header) => (
                        <th
                          key={header.value}
                          className="text-color-gray-600 uppercase py-4 whitespace-nowrap"
                        >
                          <Title
                            className="flex text-sm ml-8 max-w-fit gap-1 items-center cursor-pointer"
                            level="2"
                            onClick={() => {
                              if (header.sortingValue) {
                                setSortingFunc(header.sortingValue);
                              }
                            }}
                          >
                            {header.sortingValue && (
                              <CustomSortIcon
                                sortDirection={sorting}
                                activeHeader={header}
                              />
                            )}
                            {header.label}
                          </Title>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {orders &&
                      orders.map(
                        ({
                          id,
                          user_id,
                          number,
                          buyer,
                          seance,
                          created_at,
                          event_name,
                          city,
                          order_price,
                          status,
                          client_order_id,
                          provider,
                          count_tickets,
                          order_fee,
                        }) => (
                          <tr
                            onClick={() =>
                              window.open(
                                ADMIN_ORDERS_ROUTE + `/edit/${id}`,
                                '_blank',
                              )
                            }
                            key={id}
                            className="cursor-pointer transition-colors hover:bg-color-steel-gray-20 h-10"
                          >
                            {activeHeaders.some(
                              (h) => h.value === 'number',
                            ) && (
                              <td className="py-2" id="number">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {number || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'client_order_id',
                            ) && (
                              <td className="py-2" id="client_order_id">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {client_order_id || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'status',
                            ) && (
                              <td className="py-2" id="status">
                                {status ? (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {status === 'cancelled' && (
                                      <div className="bg-red-100 text-red-500 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Отменен'}
                                        <Icon16CancelCircleOutline
                                          color={'rgb(239 68 68)'}
                                        />
                                      </div>
                                    )}
                                    {status === 'refunded' && (
                                      <div className="bg-orange-100 text-orange-500 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Возвращен'}
                                        <Icon16Replay
                                          color={'rgb(251 146 60)'}
                                        />
                                      </div>
                                    )}
                                    {status === 'partially_refunded' && (
                                      <div className="bg-yellow-100 text-yellow-500 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Частично возвращен'}
                                        <Icon16RoubleArrowBackwardOutline
                                          color={'#facc15'}
                                        />
                                      </div>
                                    )}
                                    {status === 'paid' && (
                                      <div className="bg-green-100 text-green-600 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Оплачен'}
                                        <Icon16PaymentCardOutline
                                          color={'rgb(34 197 94)'}
                                        />
                                      </div>
                                    )}
                                    {status === 'time_changed' && (
                                      <div className="bg-blue-100 text-blue-500 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Перенос'}
                                        <Icon16RoubleArrowBackwardOutline
                                          color={'#2563eb'}
                                        />
                                      </div>
                                    )}
                                    {status === 'event_changed' && (
                                      <div className="bg-violet-100 text-violet-500 flex justify-between gap-2 items-center p-1 rounded">
                                        {'Перенос'}
                                        <Icon16RoubleArrowBackwardOutline
                                          color={'#7c3aed'}
                                        />
                                      </div>
                                    )}
                                  </Text>
                                ) : (
                                  <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                    {'-'}
                                  </Text>
                                )}
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'event_name',
                            ) && (
                              <td className="py-2" id="event_name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {event_name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'created_at',
                            ) && (
                              <td className="py-2" id="created_at">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {created_at !== null &&
                                  typeof created_at === 'string'
                                    ? parseDateFrom({
                                        date: created_at,
                                        format: 'daytime',
                                        utc: false,
                                      })
                                    : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'buyer_name',
                            ) && (
                              <td className="py-2" id="buyer_name">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {buyer?.name || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'user_id',
                            ) && (
                              <td className="py-2" id="user_id">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {user_id?.split('-').pop() || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'buyer_phone',
                            ) && (
                              <td className="py-2" id="buyer_phone">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {buyer?.phone || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'buyer_email',
                            ) && (
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {buyer?.email || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some((h) => h.value === 'city') && (
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {city || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'seance__start_date',
                            ) && (
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {seance &&
                                  typeof seance.start_date === 'string'
                                    ? parseDateFrom({
                                        date: seance.start_date,
                                        format: 'daytime',
                                        utc: false,
                                      })
                                    : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'order_price',
                            ) && (
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {order_price ? formatPrice(order_price) : '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'provider',
                            ) && (
                              <td className="py-2" id="provider">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {provider ? provider : 'Нет'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'count_tickets',
                            ) && (
                              <td className="py-2" id="count_tickets">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {count_tickets || '-'}
                                </Text>
                              </td>
                            )}
                            {activeHeaders.some(
                              (h) => h.value === 'order_fee',
                            ) && (
                              <td className="py-2">
                                <Text className="max-w-19 min-w-156 whitespace-nowrap overflow-hidden text-ellipsis ml-8">
                                  {order_fee ? formatPrice(order_fee) : 'Нет'}
                                </Text>
                              </td>
                            )}
                          </tr>
                        ),
                      )}
                  </tbody>
                </table>
              </div>
              {data && data?.count > 10 ? (
                <PaginationComponent
                  count={data?.count}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  items={orders}
                />
              ) : null}
            </>
          ) : (
            <Div>
              <Placeholder
                icon={<Icon24ListDeleteOutline />}
                header={'Заказы не найдены'}
              >
                Попробуйте изменить критерии поиска
              </Placeholder>
            </Div>
          )}
        </Group>
      )}
    </>
  );
};

export default Orders;
